exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-agile-crimes-agile-crimes-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/agile-crimes/agile-crimes.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-agile-crimes-agile-crimes-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-ai-ruins-software-development-and-chess-ai-ruins-software-development-and-chess-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/ai-ruins-software-development-and-chess/ai-ruins-software-development-and-chess.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-ai-ruins-software-development-and-chess-ai-ruins-software-development-and-chess-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-blockchain-america-blockchain-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/blockchain-america/blockchain.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-blockchain-america-blockchain-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-cleaning-pandas-cleaning-pandas-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/cleaning-pandas/cleaning-pandas.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-cleaning-pandas-cleaning-pandas-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-crocodiles-can-now-ride-bicycles-crocodiles-can-now-ride-bicycles-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/crocodiles-can-now-ride-bicycles/crocodiles-can-now-ride-bicycles.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-crocodiles-can-now-ride-bicycles-crocodiles-can-now-ride-bicycles-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-enterprise-software-for-monsters-enterprise-software-for-monsters-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/enterprise-software-for-monsters/enterprise-software-for-monsters.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-enterprise-software-for-monsters-enterprise-software-for-monsters-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-hello-hello-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/hello/hello.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-hello-hello-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-robots-robots-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/robots/robots.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-robots-robots-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-sick-software-sick-software-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/blog/sick-software/sick-software.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-blog-sick-software-sick-software-mdx" */),
  "component---src-templates-pages-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-pages-cv-cv-mdx": () => import("./../../../src/templates/pages.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/pages/cv/cv.mdx" /* webpackChunkName: "component---src-templates-pages-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-pages-cv-cv-mdx" */),
  "component---src-templates-pages-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-pages-index-index-mdx": () => import("./../../../src/templates/pages.tsx?__contentFilePath=/home/runner/work/webbiscuit-website/webbiscuit-website/pages/index/index.mdx" /* webpackChunkName: "component---src-templates-pages-tsx-content-file-path-home-runner-work-webbiscuit-website-webbiscuit-website-pages-index-index-mdx" */)
}

